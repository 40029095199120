<template>
  <div>
    <b-card
      v-if="couponData"
      no-body
      class="border-success mb-2"
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-1 pb-0">
        <h5 class="mb-0">
          {{ $t('Start Date') }}
        </h5>
        <b-badge variant="light-success">
          {{ new Date(couponData.start_date) | moment("D MMMM YYYY") }}
        </b-badge>
      </b-card-header>
      <b-card-header class="d-flex justify-content-between align-items-center pt-1 pb-1">
        <h5 class="mb-0">
          {{ $t('Stop Date') }}
        </h5>
        <b-badge variant="light-danger">
          {{ new Date(couponData.stop_date) | moment("D MMMM YYYY") }}
        </b-badge>
      </b-card-header>
    </b-card>
    <b-card
      v-if="couponData"
      no-body
      class="border-info"
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-1 pb-0">
        <h5 class="mb-0">
          {{ $t('Create') }}
        </h5>
        <b-badge variant="light-success">
          {{ new Date(couponData.create_datetime) | moment("D MMMM YYYY HH:mm:ss") }}
        </b-badge>
        <small class="text-muted w-100">{{ couponData.create_by }}</small>
      </b-card-header>
      <b-card-header class="d-flex justify-content-between align-items-center pt-1 pb-1">
        <h5 class="mb-0">
          {{ $t('Update') }}
        </h5>
        <b-badge
          variant="light-warning"
        >
          {{ (couponData.update_datetime !== '-')? (new Date(couponData.update_datetime) | moment("D MMMM YYYY HH:mm:ss")) : couponData.update_datetime }}
        </b-badge>
        <small class="text-muted w-100">{{ couponData.update_by }}</small>
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BBadge,
  },
  props: {
    couponData: {
      type: Object,
      required: true,
    },
  },
  setup() {},
}
</script>

<style>

</style>
