<template>
  <b-card>

    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              :src="couponData.image"
              :text="avatarText('Image')"
              variant="light-success"
              size="100px"
              rounded
            />
            <div class="ml-1">
              <h5 class="mb-0">
                1
              </h5>
              <small>{{ $t('Image') }}</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              :src="couponData.image_en"
              :text="avatarText('Image English')"
              variant="light-success"
              size="100px"
              rounded
            />
            <div class="ml-1">
              <h5 class="mb-0">
                1
              </h5>
              <small>{{ `${$t('Image')} (${$t('English')})` }}</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="AwardIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Brand') }}</span>
            </th>
            <td class="pb-50">
              {{ couponData.brand_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="ShoppingBagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Merchant') }}</span>
            </th>
            <td class="pb-50">
              {{ couponData.merchant_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="TagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Code') }}</span>
            </th>
            <td class="pb-50">
              {{ couponData.code }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Value') }}</span>
            </th>
            <td class="pb-50">
              {{ couponData.value }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Status') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                pill
                :variant="`light-${resolveCouponStatusVariant(couponData.status)}`"
                class="text-capitalize"
              >
                {{ $t(textFirstUpper(couponData.status)) }}
              </b-badge>
            </td>
          </tr>
        </table>

        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-button
              :to="{ name: 'ecoupon-coupon-edit', params: { id: $router.currentRoute.params.id } }"
              variant="primary"
            >
              {{ $t('Edit') }}
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteCoupon"
            >
              {{ $t('Delete') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import useCouponList from '../coupon-list/useCouponList'
import couponStoreModule from '../couponStoreModule'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge,
  },
  props: {
    couponData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteCoupon() {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('coupon-store/deleteCoupon', this.$router.currentRoute.params.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.push({ name: 'ecopon-coupon-list' })
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'coupon-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, couponStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      resolveCouponStatusVariant, textFirstUpper,
    } = useCouponList()
    return {
      avatarText,
      textFirstUpper,
      resolveCouponStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
      .swal2-popup {
        background-color: $theme-dark-body-bg !important;
      }
  }
}
</style>
