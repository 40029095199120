<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="couponData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('Error fetching coupon data') }}
      </h4>
      <div class="alert-body">
        {{ $t('No coupon found with this coupon id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'ecoupon-coupon-list'}"
        >
          {{ $t('Coupon List') }}
        </b-link>
        {{ $t('for other coupons') }}
      </div>
    </b-alert>

    <template v-if="couponData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <coupon-view-info-card :coupon-data="couponData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <coupon-view-date-card :coupon-data="couponData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <coupon-view-timeline-card />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import couponStoreModule from '../couponStoreModule'
import CouponViewInfoCard from './CouponViewInfoCard.vue'
import CouponViewDateCard from './CouponViewDateCard.vue'
import CouponViewTimelineCard from './CouponViewTimelineCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    CouponViewInfoCard,
    CouponViewDateCard,
    CouponViewTimelineCard,

  },
  setup() {
    const couponData = ref(null)

    const STORE_MODULE_NAME = 'coupon-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, couponStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    store
      .dispatch('coupon-store/getCoupon', { id: router.currentRoute.params.id })
      .then(response => { couponData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          couponData.value = undefined
        }
      })

    return {
      couponData,
    }
  },
}
</script>

<style>

</style>
